import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MainLayout from "../../../component/MainLayout";
import { usePutUserMutation } from "../../../redux/Api/adminApi";

const ManageUserEdit = () => {
  const [file, setFile] = useState();
  const { editRecord } = useSelector((state) => state.health);
  const [putUser, response] = usePutUserMutation();

  const navigate = useNavigate();

  const onFinish = async (value) => {
    console.log(value);
    // return;
    const fd = new FormData();
    fd.append("name", value.name);
    if (file) fd.append("file", file);
    const data = {
      id: editRecord._id,
      type: fd,
    };
    putUser(data);
  };
  useEffect(() => {
    if (response && response.data) {
      toast.success(response.data.message);
      navigate("/user/manage-user");
    } else if (response && response.error) {
      toast.error(response.error.data.message);
    }
  }, [response]);

  return (
    <>
      <MainLayout>
        <div className="form-user">
          <h1 className="form-head"> Manage User Edit Form</h1>
          <Form
            initialValues={{ name: editRecord?.name }}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item label="Name" name="name">
              <Input />
            </Form.Item>

            <Form.Item label="Upload Image">
              <Input type="file" onChange={(e) => setFile(e.target.files[0])} />
            </Form.Item>
            <Button type="primary" htmlType="submit" className="gred-button">
              Edit User
            </Button>
          </Form>
        </div>
      </MainLayout>
    </>
  );
};

export default ManageUserEdit;
