import { Navigate } from "react-router-dom";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import Login from "../pages/Auth/Login";
import Varification from "../pages/Auth/Varification";
import ViewPp from "../pages/Static/P&P/ViewPp";
import ViewTc from "../pages/Static/T&C/ViewTc";

const AuthRoute = [
  { path: "/", element: <Login /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/varification", element: <Varification /> },
  { path: "/view-privacy-policy", element: <ViewPp /> },
  { path: "/view-term-condation", element: <ViewTc /> },
  { path: "*", element: <Navigate to="/" replace /> },
];

export default AuthRoute;
