import { Table } from "antd";
import React from "react";
import MainLayout from "../../component/MainLayout";
import { useGetTransactionQuery } from "../../redux/Api/adminApi";

const Transections = () => {
  const { data, isLoading } = useGetTransactionQuery();
 
  const columns = [
    {
      key: "1",
      title: "Id",
      dataIndex: "_id",
    },
    {
      key: "2",
      title: "Amount",
      dataIndex: "amount",
    },
    {
      key: "3",
      title: "Booking Id",
      dataIndex: "bookingId",
    },
    {
      key: "4",
      title: "Payment-type",
      dataIndex: "paymentType",
    },
    
    {
      key: "5",
      title: "Status",
      render: (record) => {
      return(  record.status === "succeeded" ? (
          <p style={{ color: "green", marginLeft: "20px" }}>Success</p>
        ) : (
          <p style={{ color: "red", marginLeft: "20px" }}>Panding </p>
        )
      )
      },
    },
  ];

  return (
    <>
      <MainLayout>
        <div className="table">
          <Table
            dataSource={data && data.data}
            columns={columns}
            scroll={{ y: 400, x: 1000 }}
            bordered
            loading={isLoading}
          />
        </div>
      </MainLayout>
    </>
  );
};

export default Transections;
