import React from "react";
import { useGetStaticQuery } from "../../../redux/Api/adminApi";

const ViewPp = () => {
    const { data } = useGetStaticQuery();
    const viewData = data &&data.data.privacy_policy.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/g, " ");
    
  return (
      <div className="view">
        <div>
          <h1 className="view_header">Privacy and Policy</h1>
        </div>
        <hr className="view_hr" />
        <p className="view_para">{viewData}</p>
      </div>
       );
};

export default ViewPp;
