import React, { useEffect} from "react";

import { Button, Table } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import MainLayout from "../../../component/MainLayout";
import {
  useDelStaticMutation,
  useGetStaticQuery,
} from "../../../redux/Api/adminApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { showEditModalOpen } from "../../../redux/slice/healthSlice";
import { useDispatch } from "react-redux";

const Privacy = () => {
  const { data, isLoading } = useGetStaticQuery();
  const [delStatic, response] = useDelStaticMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const columns = [
    {
      key: "1",
      title: "Id",
      dataIndex: "_id",
    },
    {
      key: "2",
      title: "Privacy Policy",
      render:(record) => record && record.privacy_policy.replace( /(<([^>]+)>)/ig, '').replace(/&nbsp;/g, " ")
    },

    {
      key: "3",
      title: "Action",
      render: (record) => {
        return (
          <>
            <EditOutlined onClick={() => editPnp(record)} className="editIcon"/>
            <DeleteOutlined onClick={() => delpnp(record)} className="delIcon"/>
          </>
        );
      },
    },
  ];

  const editPnp = (record) => {
    dispatch(showEditModalOpen(record));
    navigate("/static/update-privacy-policy")
  };

  const delpnp = (record) => {
    let del = { condition: 1 };
    const data = { id: record._id, type: del };
    delStatic(data);
  };

  useEffect(() => {
    if (response && response.data) {
      toast.success(response.data.message);
    }
  }, [response]);

  return (
    <MainLayout>
      <div className="table">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button className="gred-button">
        <a href={`/view-term-condation`} target="_blank" rel="noreferrer">
          View
          </a>
        </Button>
      </div>
        <Table
          dataSource={[data&&data.data]}
          columns={columns}
          scroll={{ y: 400, x: 1000 }}
          bordered
          loading={isLoading}
        />
      </div>
    </MainLayout>
  );
};

export default Privacy;
