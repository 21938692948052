import React from "react";
import "../../styles/forget.css";

import { useNavigate } from "react-router-dom";

import { Button, Form, Input } from "antd";
import { ArrowLeftOutlined, LockOutlined } from "@ant-design/icons";

import { passRule } from "../../Common/FormValidation";


const Varification = () => {
  const navigate = useNavigate();

  const onFinish = (value) => {
    console.log(value);
  };

  return (
    <>
      <div className="forget">
        <div className="forget-main">
          <h1>
            <ArrowLeftOutlined onClick={() => navigate("/forgot-password")} />
          </h1>
          <h1 className="forget-head">Verification</h1>
          <p className="forget-para">
          We’ve send you the verification code on<br/> <span className="varifi-email">jhonewilliams@gmail.com</span>
          </p>
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item name="password" rules={passRule}>
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <div className="login-button">
              <Button className="gre-button" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Varification;
