import React from "react";
import { Image, Menu } from "antd";
import {
  HomeOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
  FileProtectOutlined,
} from "@ant-design/icons";
import {
  PlusOutlined,
  AuditOutlined,
  DeploymentUnitOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import {
  ReadOutlined,
  ClockCircleOutlined,
  RightSquareOutlined,
  SettingOutlined,
  SubnodeOutlined,
  TransactionOutlined,
} from "@ant-design/icons";
import { RxCrossCircled } from "react-icons/rx";
import { RiSettings6Line } from "react-icons/ri";
import { MdAssignmentTurnedIn } from "react-icons/md";
import {
  AiFillInsurance,
  AiOutlineCreditCard,
  AiFillWallet,
} from "react-icons/ai";
import { GiPayMoney } from "react-icons/gi";
import { MdInput } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const SideBar = () => {
  const navigate = useNavigate();
  const activateCurrentMainMenu = window.location.pathname.split("/")[1];
  return (
    <>
      <div className="side-head">
        <Image src="/assets/images/logo3d2.png" width={70} height={100} />
      </div>
      <Menu
        mode="inline"
        className="sider-menu"
        onClick={({ key }) => {
          navigate(key);
        }}
        defaultSelectedKeys={[window.location.pathname]}
        defaultOpenKeys={[`/${activateCurrentMainMenu}`]}
        items={[
          {
            key: "/dashboard",
            icon: <HomeOutlined />,
            label: "Dashboard",
          },
          {
            key: "/user",
            icon: <UserOutlined />,
            label: "Manage Users",
            children: [
              {
                key: "/user/manage-user",
                icon: <UsergroupAddOutlined />,
                label: "User",
              },
              {
                key: "/user/manage-provider",
                icon: <UserAddOutlined />,
                label: "Provider",
              },
            ],
          },
          {
            key: "/booking",
            icon: <ReadOutlined />,
            label: "Manage Booking",
            children: [
              {
                key: "/booking/pending",
                icon: <ClockCircleOutlined />,
                label: "Pending",
              },
              {
                key: "/booking/accept",
                icon: <MdAssignmentTurnedIn />,
                label: "Accept",
              },
              {
                key: "/booking/upcoming",
                icon: <RightSquareOutlined />,
                label: "Upcoming",
              },
              {
                key: "/booking/ongoing",
                icon: <RiSettings6Line />,
                label: "Ongoing",
              },
              {
                key: "/booking/complete",
                icon: <CheckSquareOutlined />,
                label: "Complete",
              },

              {
                key: "/booking/reject",
                icon: <RxCrossCircled />,
                label: "Reject",
              },
            ],
          },
          {
            key: "/payment",
            icon: <AiFillWallet />,
            label: "Manage Payment",
            children: [
              // {
              //   key: "/payment/card",
              //   icon: <AiOutlineCreditCard />,
              //   label: "Debit/Credit",
              // },
              {
                key: "/payment/insurance",
                icon: <AiFillInsurance />,
                label: "Insurance",
              },
            ],
          },
          {
            key: "/cms",
            icon: <SubnodeOutlined />,
            label: "CMS",
            children: [
              {
                key: "/cms/chronic",
                icon: <PlusOutlined />,
                label: "Chronic Diseases",
              },
              // {
              //   key: "/cms/health",
              //   icon: <PlusOutlined />,
              //   label: "Health Complaints",
              // },
              {
                key: "/cms/specialzation",
                icon: <SettingOutlined />,
                label: "Specialization",
              },
              {
                key: "/cms/skill",
                icon: <DeploymentUnitOutlined />,
                label: "Skill",
              },
              {
                key: "/cms/qualification",
                icon: <AuditOutlined />,
                label: "Qualification",
              },
            ],
          },
          {
            key: "/static",
            icon: <FileProtectOutlined />,
            label: "Static",
            children: [
              {
                key: "/static/term-and-condation",
                icon: <PlusOutlined />,
                label: "Term & Condation ",
              },
              {
                key: "/static/privacy-policy",
                icon: <SettingOutlined />,
                label: "Privacy Policy",
              },
            ],
          },
          {
            key: "/transaction",
            icon: <TransactionOutlined />,
            label: "Transactions",
          },
          {
            key: "/payout",
            icon: <GiPayMoney />,
            label: "Payout",
          },
          {
            key: "/booking/manage-booking-field",
            icon: <MdInput />,
            label: "Manage Booking Field",
          },
        ]}
      />
    </>
  );
};

export default SideBar;
