import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "antd/dist/reset.css";
import "react-toastify/dist/ReactToastify.css";

import { store } from "./redux/store";
import { Provider } from 'react-redux'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Provider store={store}>
      <App />
      <ToastContainer position="top-right" autoClose={5000} />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
