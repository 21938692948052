import React from "react";

import { Layout } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import SideBar from "./SideBar";
import MainHeader from "./MainHeader";
import Breadcrumbb from "./Breadcrumbb";

const MainLayout = ({ children }) => {
  return (
    <>
      <Layout className="container">
        <Sider className="sider"collapsedWidth="0"breakpoint="lg"  width={250}>
          <SideBar />
        </Sider>
        <Layout>
          <Header className="header">
            <MainHeader />
          </Header>
          <Content>
            <div className="bread">
              <Breadcrumbb />
            </div>
            {children}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default MainLayout;
