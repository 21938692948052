import { Button, Table } from "antd";
import { toast } from "react-toastify";
import MainLayout from "../../component/MainLayout";
import { useGetPayoutQuery, usePutPayoutMutation } from "../../redux/Api/adminApi";

const Payout = () => {
  const { data, isLoading } = useGetPayoutQuery();
  const [putPayout] = usePutPayoutMutation()

  const columns = [
    {
      key: "1",
      title: "Id",
      dataIndex: "_id",
    },
    {
      key: "2",
      title: "Amount",
      dataIndex: "amount",
    },
    {
      key: "3",
      title: "Provider Name",
      render: (record) => record.provider && record.provider.name ,
    },
     {
      key: "4",
      title: "Provider Email",
      render: (record) => record.provider && record.provider.email ,
    },
    {
      key: "5",
      title: " Balance",
      render: (record) => record.provider && record.provider.balance ,
    },
    {
      key: "6",
      title: " Total Balance",
      render: (record) => record.provider && record.provider.totalBalance ,
    },
    {
      key: "7",
      title: "Status",
      render: (record) => {
        return record.adminVerify === 1 ? (
          <p style={{ color: "green", marginLeft: "20px" }}>Success</p>
        ) : record.adminVerify === 0 ? (
          <p style={{ color: "orange", marginLeft: "20px" }}>Panding</p>
        ) : (
          <p style={{ color: "red", marginLeft: "20px" }}>Reject</p>
        );
      },
    },
    {
      key: "8",
      title: "Action",
      render: (record) => (
        <>
        <Button className="gre-button" onClick={() => handelReject(record)}>
          Reject
        </Button>
        <Button className="gre-button" onClick={() => handelVerify(record)}>
          Verify
        </Button>
        </>
      ),
    },
   ];


   const handelReject = async(record) => {
    let con = { adminVerify: "2" };
    let data = { id: record._id, type: con };
    let reject = await putPayout(data);
    console.log(reject)
    if(reject.error && reject.error.status===400){
       toast.warning(reject.error.data.message);
    }else if(reject && reject.data.status===200){
       toast.success(reject && reject.data.message);
    }
  };
  const handelVerify = async(record) => {
    let con = { adminVerify:"1" };
    let data = { id: record._id, type: con };
    let varify = await putPayout(data);
    if(varify.error && varify.error.status===400){
       toast.warning(varify.error.data.message);
    }
    else{
       toast.success(varify && varify.data.message);
    }
  };


  return (
    <>
      <MainLayout>
        <div className="table">
          <Table
            dataSource={data && data.data}
            columns={columns}
            scroll={{ y: 400, x: 1000 }}
            bordered
            loading={isLoading}
          />
        </div>
      </MainLayout>
    </>
  );
};

export default Payout;
