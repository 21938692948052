import React from "react";
import "../../styles/login.css";

import { Button, Checkbox, Form, Image, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { post } from "../../halpers/api_helper";
import { LOGIN } from "../../halpers/url_helper";
import { emailRule, passRule } from "../../Common/FormValidation";




const Login = () => {
  const navigate = useNavigate();

  const onsubmit = async (value) => {
    if (value.remember === false) {
      return toast.error("check remember me");
    } else {
      let val= {email:value.email,password:value.password}
      const data = await post(LOGIN, val);
      if (data) {
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("user", data.data.user.email);
        toast.success(data.message);
        navigate("/dashboard");
      }
    }
  };

  return (
    <>
      <div className="login">
        <div className="login-main">
          <div className="login-upper">
            <div className="login-img">
              <Image src="assets/images/logo3d2.png" className="img"/>
            </div>
          </div>
          <div className="login-lower">
            <h1 className="login-head">Welcome Back</h1>
            <p className="login-para">Enter your Login Detail</p>
            <div className="login-frm">
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: false }}
              onFinish={onsubmit}
            >
              <Form.Item name="email" rules={emailRule}>
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="email"
                />
              </Form.Item>
              <Form.Item name="password" rules={passRule}>
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Link to="/forgot-password" className="login-form-forgot">
                  Forgot password
                </Link>
              </Form.Item>

              <Form.Item>
                <Button htmlType="submit" className="gre-button">
                  Log in
                </Button>
              </Form.Item>
            </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
