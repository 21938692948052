import axios from "axios";
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_BASE_URL;

const instance = axios.create({baseURL});

instance.defaults.headers.common["Authorization"] = '';

export const get = async(endpoint) => {
    try {
     const data = await instance.get(endpoint)
     return data
    } catch (error) {
      toast.error(error.response.data.message)  
    }

}

export const post = async(endpoint,value) => {
    try {
     const {data} = await instance.post(endpoint,value)
     return data
    } catch (error) {
      toast.error(error.response.data.message)  
    }

}

export const put = async(endpoint,value) => {
    try {
     const data = await instance.put(endpoint,value)
     return data
    } catch (error) {
      toast.error(error.response.data.message)  
    }

}

export const patch = async(endpoint,value) => {
    try {
     const data = await instance.patch(endpoint,value)
     return data
    } catch (error) {
      console.log(error.response.data.message)  
    }

}

export const del = async(endpoint) => {
    try {
     const data = await instance.delete(endpoint)
     return data
    } catch (error) {
      toast.error(error.response.data.message)
    }

}