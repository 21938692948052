import { Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showAddModalClose } from "../../../redux/slice/healthSlice";
import { showEditModalClose } from "../../../redux/slice/healthSlice";
import {usePostQualificationMutation,usePutQualificationMutation} from "../../../redux/Api/adminApi";
import { toast } from "react-toastify";

export const QuqlificationAddModal = () => {
  const [qualAdd, setqualAdd] = useState();

  const { isModalAdd } = useSelector((state) => state.health);
  const [postQualification,response] = usePostQualificationMutation();

  const dispatch = useDispatch();


  const handleOk = () => {
    let data = { name: qualAdd };
    if(qualAdd === undefined){
      return toast.error("please fill the data")
    }
    postQualification(data);
    dispatch(showAddModalClose());
  };
  useEffect(() => {
    if(response && response.data){
      toast.success( response.data.message)
    }
   },[response])

  return (
    <div>
      <Modal
        title="Add"
        open={isModalAdd}
        onOk={handleOk}
        onCancel={() => dispatch(showAddModalClose())}
        okButtonProps={{ style: { background: 'var(--gradient)' } }} 
        okText="Add"
      >
        <Input
          placeholder="Enter Name"
          onChange={(e) => setqualAdd(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export const QuqlificationEditModal = () => {
  const [qualEdit, setQualEdit] = useState();

  const { isModalEdit, editRecord } = useSelector((state) => state.health);
  const dispatch = useDispatch();
  const [putQualification,response] = usePutQualificationMutation();


  const handleOk = () => {
    let data = {
      id: editRecord._id,
      type: qualEdit,
    };
    if(qualEdit === undefined){
      return toast.error("please fill the data")
    }
    putQualification(data);
    dispatch(showEditModalClose());
  };
  useEffect(() => {
    if(response && response.data){
      toast.success( response.data.message)
    }
   },[response])

  return (
    <div>
      <Modal
        title="Edit"
        open={isModalEdit}
        onOk={handleOk}
        onCancel={() => dispatch(showEditModalClose())}
        okButtonProps={{ style: { background: 'var(--gradient)' } }} 
        okText="Edit"
      >
        <Input
          placeholder="Enter Type"
          onChange={(e) => setQualEdit(e.target.value)}
        />
      </Modal>
    </div>
  );
};
