import { Card, Image, List, Row } from "antd";
import React from "react";
import MainLayout from "../../component/MainLayout";
import { useGetDashboardQuery } from "../../redux/Api/adminApi";

const Dashboard = () => {
  const { data } = useGetDashboardQuery();
  console.log(data);
  return (
    <>
      <MainLayout>
        <div className="card-main">
          <Row grid={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 6 }}>
            <Card  className="card">
              <div className="card-gap">
                <h1 className="card-head">{data&&data.data.user_total}</h1>
                <h4 className="card_main" >User</h4>
              </div>
            </Card>

            <Card  className="card">
              <div className="card-gap">
                <h1 className="card-head">{data&&data.data.bookings_total}</h1>
                <h4 className="card_main" >Booking</h4>
              </div>
            </Card>

            <Card className="card">
              <div className="card-gap">
                <h1 className="card-head">
                  {data&&data.data.upcoming_bookings_total}
                </h1>
                <h4 className="card_main" >Upcoming Bookings</h4>
              </div>
            </Card>

            <Card className="card">
              <div className="card-gap">
                <h1 className="card-head">
                  {data&&data.data.complete_bookings_total}
                </h1>
                <h4 className="card_main" >Complete Booking</h4>
              </div>
            </Card>

            <Card className="card">
              <div className="card-gap">
                <h1 className="card-head">{data&&data.data.provider_total}</h1>
                <h4 className="card_main" >Provider Total</h4>
              </div>
            </Card>

            <Card  className="card">
              <div className="card-gap">
                <h1 className="card-head">
                  {data&&data.data.licensed_provider_total}
                </h1>
                <h4 className="card_main" >Licensed Provider</h4>
              </div>
            </Card>

             <Card  className="card">
              <div className="card-gap">
                <h1 className="card-head">
                  {data&&data.data.unlicensed_provider_total}
                </h1>
                <h4 className="card_main" >Unlicensed Provider</h4>
              </div>
            </Card>

            <Card  className="card">
              <div className="card-gap">
                <h1 className="card-head">
                  {data&&data.data.unlicensed_provider_total}
                </h1>
                <h4 className="card_main" >Unlicensed Provider</h4>
              </div>
            </Card>

           
          </Row>
        </div>
      </MainLayout>
    </>
  );
};

export default Dashboard;
