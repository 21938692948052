import { Button, Form, Input, InputNumber, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MainLayout from "../../component/MainLayout";
import { usePostFieldMutation } from "../../redux/Api/adminApi";
import {
  nameRule,
} from "../../Common/FormValidation";

import en from "react-phone-number-input/locale/en.json";
import "react-phone-number-input/style.css";

const ManageFieldAddForm = () => {
  const [postField] = usePostFieldMutation();
  const navigate = useNavigate();
  const [labels, setLabels] = useState(en);

  const onFinish = async (value) => {
    const res = await postField(value);
    console.log(res);
    if (res.error && res.error.status === 400) {
      toast.error(res.error.data.message);
    } else if (res.data && res.data.status === 201) {
      toast.success(res.data.message);
      navigate("/booking/manage-booking-field");
    }
  };

  return (
    <>
      <MainLayout>
        <div className="form-user">
          <h1 className="form-head"> Add Fields Form</h1>
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item label="Name" name="name" rules={nameRule}>
              <Input />
            </Form.Item>
            <Form.Item label="Enable Field" name="status"   initialValue={true}>
                     <Select options={[
                        { value: true, label: <span>Enable</span> },
                        { value: false, label: <span>Disable</span> }

                        ]} />
            </Form.Item>
            <Form.Item label="Show Normal / Abnormal" name="showNormal"   initialValue={true}>
                     <Select options={[
                        { value: true, label: <span>Enable</span> },
                        { value: false, label: <span>Disable</span> }

                        ]} />
            </Form.Item>

            <Form.Item label="Show Other Field" name="showOther"  initialValue={true}>
                     <Select options={[
                        { value: true, label: <span>Enable</span> },
                        { value: false, label: <span>Disable</span> }

                        ]} />
            </Form.Item>
            <Button htmlType="submit" className="gred-button">
              Add New
            </Button>
          </Form>
        </div>
      </MainLayout>
    </>
  );
};

export default ManageFieldAddForm;
