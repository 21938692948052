import React, { useState, useRef, useEffect} from "react";
import JoditEditor from "jodit-react";
import MainLayout from "../../../component/MainLayout";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { usePutStaticMutation } from "../../../redux/Api/adminApi";
import { toast } from "react-toastify";
import {useNavigate } from "react-router-dom";

const UpdatePrivacy = () => {
  const {editRecord} = useSelector((state) => state.health)
    const editor = useRef(null);
    const htmlData = editRecord&&editRecord.privacy_policy.replace( /(<([^>]+)>)/ig, '').replace(/&nbsp;/g, " ")
    const [content, setContent] = useState(htmlData);
        
   const [putStatic,response]=usePutStaticMutation()
   const navigate = useNavigate()

    const handelSave = () => { 
        const data= {id:editRecord._id,type:{privacy_policy:content}}
        putStatic(data)
    }
  
    useEffect(() => {
      if(response && response.data){
        toast.success( response.data.message)
        navigate("/static/privacy-policy")
      }
     },[response])



  return (
   <MainLayout>
       <div className="table">
       <div style={{ marginTop: "20px" }}>
          <Button
            onClick={() => navigate("/static/privacy-policy")}
            className="gred-button"
          >
            Back
          </Button>
        </div>
        <JoditEditor
          ref={editor}
          value={content}
          onBlur={newContent => setContent(newContent)}
          onChange={(newContent) => {}}
        />
        <div style={{marginTop:"20px"}}>

        <Button onClick={handelSave} className="gred-button">Save</Button>
        </div>
      </div>
   </MainLayout>
  )
}

export default UpdatePrivacy
