import { Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showAddModalClose } from "../../../redux/slice/healthSlice";
import { showEditModalClose } from "../../../redux/slice/healthSlice";
import {
  useCreateChronicDiseaseMutation,
  useEditChronicDiseaseMutation,
  usePostSkillMutation,
  usePutSkillMutation,
} from "../../../redux/Api/adminApi";
import { toast } from "react-toastify";

export const ChronicAddModal = () => {
  const [skillAdd, setSkillAdd] = useState();

  const { isModalAdd } = useSelector((state) => state.health);
  const dispatch = useDispatch();

  const [postHealth, response] = useCreateChronicDiseaseMutation();

  const handleOk = () => {
    if (!skillAdd) {
      return toast.error("Please fill the data");
    }
    postHealth(skillAdd);
    dispatch(showAddModalClose());
  };

  const handelChange = (e) => {
    let { name, value } = e.target;
    setSkillAdd({ ...skillAdd, [name]: value });
  };
  useEffect(() => {
    if (response && response.data) {
      toast.success(response.data.message);
    }
  }, [response]);

  return (
    <div>
      <Modal
        title="Add"
        open={isModalAdd}
        onOk={handleOk}
        onCancel={() => dispatch(showAddModalClose())}
        okButtonProps={{ style: { background: "var(--gradient)" } }}
        okText="Add"
      >
        <div className="modal-input">
          <Input
            placeholder="Enter Name"
            onChange={(e) => handelChange(e)}
            name="name"
          />
        </div>
      </Modal>
    </div>
  );
};

export const ChronicEditModal = () => {
  const [skillEdit, setSkillEdit] = useState();
  const { isModalEdit, editRecord } = useSelector((state) => state.health);
  const dispatch = useDispatch();
  const [putSkill, response] = useEditChronicDiseaseMutation();

  const handleOk = () => {
    let data = {
      id: editRecord._id,
      type: skillEdit,
    };
    if (skillEdit === undefined) {
      return toast.error("please fill the data");
    }
    putSkill(data);
    dispatch(showEditModalClose());
  };
  const handelChange = (e) => {
    let { name, value } = e.target;
    setSkillEdit({ ...skillEdit, [name]: value });
  };

  useEffect(() => {
    if (response && response.data) {
      toast.success(response.data.message);
    }
  }, [response]);

  return (
    <div>
      <Modal
        title="Edit"
        open={isModalEdit}
        onOk={handleOk}
        onCancel={() => dispatch(showEditModalClose())}
        okButtonProps={{ style: { background: "var(--gradient)" } }}
        okText="Edit"
      >
        <div className="modal-input">
          <Input
            placeholder="Enter Name"
            onChange={(e) => handelChange(e)}
            name="name"
          />
        </div>
      </Modal>
    </div>
  );
};
