import { useRoutes } from 'react-router-dom';

import AuthRoute from './AuthRoute';
import MainRoute from './MainRoute';

export default function ThemeRoutes() {
    const token = localStorage.getItem('token');;
    let routes = [];
    routes = !token ? AuthRoute : MainRoute;
    return useRoutes(routes);
}

