export const emailRule = [
  { type: "email", message: "E-mail is Not Valid" },
  { required: true, message: "E-mail Required Field" },
];

export const nameRule = [
  { required: true, message: "Name Required Field" },
  { min: 3, message: "Please input Minumim 3 Charractor" },
];
export const phoneRule = [{ required: true, message: "Phone Required Field" }];
export const countryRule = [
  { required: true, message: "Country Required Field" },
];
export const levelRule = [{ required: true, message: "Level is required" }];

export const passRule = [
  { required: true, message: "Password Required Field" },
  { min: 4, message: "Please input Minumim 4 " },
];
export const cpassRule = [
  { required: true, message: "Confirm Password Required Field" },
  { min: 4, message: "Please input Minumim 4 " },
];
