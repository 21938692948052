import { Button, Form, Input, InputNumber, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MainLayout from "../../../component/MainLayout";
import { usePostUserMutation } from "../../../redux/Api/adminApi";
import {
  emailRule,
  nameRule,
  passRule,
  cpassRule,
  countryRule,
  phoneRule,
} from "../../../Common/FormValidation";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import "react-phone-number-input/style.css";

const ManageUserAddForm = () => {
  const [postUser] = usePostUserMutation();
  const navigate = useNavigate();
  const [labels, setLabels] = useState(en);

  const onFinish = async (value) => {
    const res = await postUser(value);
    console.log(res);
    if (res.error && res.error.status === 400) {
      toast.error(res.error.data.message);
    } else if (res.data && res.data.status === 201) {
      toast.success(res.data.message);
      navigate("/user/manage-user");
    }
  };

  return (
    <>
      <MainLayout>
        <div className="form-user">
          <h1 className="form-head"> Manage User Form</h1>
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item label="Name" name="name" rules={nameRule}>
              <Input />
            </Form.Item>

            <Form.Item label="Email" name="email" rules={emailRule}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Country Code"
              name="countryCode"
              rules={countryRule}
            >
              <Select>
                {getCountries().map((country) => (
                  <option key={country} value={getCountryCallingCode(country)}>
                    {labels[country]} +{getCountryCallingCode(country)}
                  </option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Phone" name="phone" rules={phoneRule}>
              <Input type="number" />
            </Form.Item>

            <Form.Item label="Password" name="password" rules={passRule}>
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={cpassRule}
            >
              <Input.Password />
            </Form.Item>

            <Button htmlType="submit" className="gred-button">
              Add User
            </Button>
          </Form>
        </div>
      </MainLayout>
    </>
  );
};

export default ManageUserAddForm;
