import { Input, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import {showAddModalClose} from "../../../redux/slice/healthSlice"
import {showEditModalClose} from "../../../redux/slice/healthSlice"
import {usePostSkillMutation,usePutSkillMutation} from "../../../redux/Api/adminApi"
import { toast } from 'react-toastify'


export const SkillAddModal = () => {
    const [skillAdd,setSkillAdd]=useState()

    const {isModalAdd} = useSelector((state) => state.health)
    const dispatch = useDispatch()

    const [postHealth,response] = usePostSkillMutation()

    const handleOk =() => {
      if(skillAdd === undefined){
        return toast.error("please fill the data")
      }
        postHealth(skillAdd)
        dispatch(showAddModalClose())
      };

      const handelChange = (e) => { 
        let {name,value} = e.target
        setSkillAdd({...skillAdd,[name]:value})
        }
        useEffect(() => {
          if(response && response.data){
            toast.success( response.data.message)
          }
         },[response])

  return (
    <div>
       
      <Modal
          title="Add"
          open={isModalAdd}
          onOk={handleOk}
          onCancel={() =>dispatch(showAddModalClose())}
          okButtonProps={{ style: { background: 'var(--gradient)' } }} 
          okText="Add"
        >
          <div className="modal-input" >
          <Input
            placeholder="Enter Name"
            onChange={(e) => handelChange(e)}
            name="name"
             />
          </div>
          
          <div className="modal-input" >
          <Input
            placeholder="Enter Type"
            onChange={(e) => handelChange(e)}
            name="type"
             />
             </div>
           <div className="modal-input" >
          <Input
            placeholder="Enter Status"
            onChange={(e) => handelChange(e)}
            name="status"
          />
          </div>
          </Modal>
    </div>
  )
}


export const SkillEditModal = () => {
    const [skillEdit,setSkillEdit]=useState()
    const {isModalEdit,editRecord} = useSelector((state) => state.health)
    const dispatch = useDispatch()
    const [putSkill,response] = usePutSkillMutation()
    
    const handleOk =() => {
        let data = {
            id:editRecord._id,
            type:skillEdit
        }
        if(skillEdit === undefined){
          return toast.error("please fill the data")
        }
        putSkill(data)
        dispatch(showEditModalClose()) 
      };
      const handelChange = (e) => { 
        let {name,value} = e.target
        setSkillEdit({...skillEdit,[name]:value})
        }

        useEffect(() => {
          if(response && response.data){
            toast.success( response.data.message)
          }
         },[response])

    return (
      <div>
      <Modal
          title="Edit"
          open={isModalEdit}
          onOk={handleOk}
          onCancel={() =>dispatch(showEditModalClose())}
          okButtonProps={{ style: { background: 'var(--gradient)' } }} 
          okText="Edit"
        >
          <div className="modal-input" >
          <Input
            placeholder="Enter Name"
            onChange={(e) => handelChange(e)}
            name="name"
             />
          </div>
          
          <div className="modal-input" >
          <Input
            placeholder="Enter Type"
            onChange={(e) => handelChange(e)}
            name="type"
             />
             </div>
           <div className="modal-input" >
          <Input
            placeholder="Enter Status"
            onChange={(e) => handelChange(e)}
            name="status"
          />
          </div>
          </Modal>
      </div>
    )
  }


