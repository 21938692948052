import { Table } from 'antd'
import React from 'react'
import { getDate } from '../../Common/DateFun'
import MainLayout from "../../component/MainLayout"
import { useGetBookingQuery } from '../../redux/Api/adminApi'

const Pending = () => {
  const {data,isLoading}=useGetBookingQuery(0)

  const columns = [
    {
      key: "1",
      title: "Id",
      dataIndex: "_id",
    },
    {
      key: "2",
      title: "Doctor Name",
      render: (record) => record.doctorId&& record.doctorId.name,
    },
    {
      key: "3",
      title: "User Name",
      render: (record) =>record.userId.name,
     
    },
    {
      key: "4",
      title: "Description",
      dataIndex: "description",
    },
    {
      key: "5",
      title: "Date",
      render: (record) =>record.selectedDateTime.split(" ")[0],
     },
  ];



  return (
    <>
    <MainLayout>
    <div className="table">
        <Table
          dataSource={data && data.list}
          columns={columns}
          scroll={{ y: 400, x: 1000 }}
          bordered
          loading={isLoading}
        />
      </div>
    </MainLayout>
    </>
  )
}

export default Pending
