
import React from "react";
import MainLayout from "../../component/MainLayout";


const ManageCard = () => {
 
    
  return (
    <>
      <MainLayout>
        Card Payment
      </MainLayout>
    </>
  );
};

export default ManageCard;
