import { configureStore } from '@reduxjs/toolkit'
import healthReducer from "../redux/slice/healthSlice"
import { adminApi } from './Api/adminApi'

export const store = configureStore({
  reducer: {
     health:healthReducer,
     [adminApi.reducerPath]: adminApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(adminApi.middleware),
})