import { Button,Image,Table } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../component/MainLayout";
import {AiFillEdit} from "react-icons/ai"
import { useGetUserQuery } from "../../../redux/Api/adminApi";
import { useDispatch } from "react-redux";
import { showEditModalOpen } from "../../../redux/slice/healthSlice";

const ManageUser = () => {
 const {data,isLoading} = useGetUserQuery()
  const navigate = useNavigate(); 
  const dispatch  = useDispatch()

  const columns = [
    {
      key: "1",
      title: "Id",
      dataIndex: "id",
    },
    {
      key: "2",
      title: "User Name",
      dataIndex: "name",
    },
    {
      key: "3",
      title: "Email",
      dataIndex: "email",
    },
    {
      key: "4",
      title: "Image Url",
      dataIndex: "imageUrl",
      render: (record) => record ? 
      <Image width={50}height={50}src={record}fallback="Uers-image"/> : "No Image"
     },
    {
      key: "5",
      title: "Action",
      render: (record) => <AiFillEdit onClick={() =>handelEdit(record)} style={{ color: "green", marginLeft: "20px" }}/>
    },
  ];

  const handelEdit =(record) => {
    dispatch(showEditModalOpen(record))
    navigate("/user/user-edit-form")
  }

  return (
    <>
      <MainLayout>
          <div className="table">
            <div style={{display:"flex", justifyContent:"space-between",alignItems:"center"}}>
            <Button onClick={() => navigate("/user/user-add-form")} className="gred-button">
              Add User
            </Button>
            </div>
            <Table
               dataSource={data&&data.data}
              columns={columns}
              scroll={{ y: 400, x: 1000 }}
              bordered
              loading={isLoading}
            />
          </div>
      </MainLayout>
    </>
  );
};

export default ManageUser;
