import { Button,Image,Table } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../component/MainLayout";
import {AiFillEdit} from "react-icons/ai"
import { useGetFieldQuery } from "../../redux/Api/adminApi";
import { useDispatch } from "react-redux";
import { showEditModalOpen } from "../../redux/slice/healthSlice";

const Fields = () => {
 const {data,isLoading} = useGetFieldQuery()
  const navigate = useNavigate();
  const dispatch  = useDispatch()
 
  const columns = [
    {
      key: "1",
      title: "Id",
      dataIndex: "_id",
    },
    {
      key: "2",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "3",
      title: "Show Normal & Abnormal",
      dataIndex: "showNormal",
      render: (record) => record ?
       "True": "False"
    },
    {
      key: "4",
      title: "Show Other Field",
      dataIndex: "showOther",
      render: (record) => record ?
      "True": "False"
     },
    {
      key: "5",
      title: "Action",
      render: (record) => <AiFillEdit onClick={() =>handelEdit(record)} style={{ color: "green", marginLeft: "20px" }}/>
    },
  ];

  const handelEdit =(record) => {
    dispatch(showEditModalOpen(record))
    navigate("/booking/edit-booking-field")
  }

  return (
    <>
      <MainLayout>
          <div className="table">
            <div style={{display:"flex", justifyContent:"space-between",alignItems:"center"}}>
            <Button onClick={() => navigate("/booking/add-booking-field")} className="gred-button">
              Add New
            </Button>
            </div>
            <Table
               dataSource={data&&data.data}
              columns={columns}
              scroll={{ y: 400, x: 1000 }}
              bordered
              loading={isLoading}
            />
          </div>
      </MainLayout>
    </>
  );
};

export default Fields;
