import { Button, Table } from "antd";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import MainLayout from "../../component/MainLayout";
import {
  useGetPaymentQuery,
  usePutPaymentMutation,
} from "../../redux/Api/adminApi";

const ManageInsurance = () => {
  const { data, isLoading } = useGetPaymentQuery(2);
  const [putPayment, response] = usePutPaymentMutation();

  const columns = [
    {
      key: "1",
      title: "Id",
      dataIndex: "_id",
    },
    {
      key: "2",
      title: "User Name",
      render: (record) => record.user && record.user.name,
    },
    {
      key: "3",
      title: "Verification",
      dataIndex: "verification",
    },
    {
      key: "4",
      title: "documents",
      render: (record) =>
        record.documents.map((doc, i) => (
          <a
            href={`http://akandoc-backend.alcax.com${doc}`}
            key={i}
            target="_blank"
            rel="noreferrer"
          >
            <Button className="gre-button">View</Button>
          </a>
        )),
    },
    {
      key: "5",
      title: "Payment Type",
      dataIndex: "paymentType",
    },
    {
      key: "6",
      title: "Action",
      render: (record) => (
        <>
        <Button className="gre-button" onClick={() => handelReject(record)}>
          Reject
        </Button>
        <Button className="gre-button" onClick={() => handelVerify(record)}>
          Verify
        </Button>
        </>
      ),
    },
  ];

  const handelReject = (record) => {
    let con = { adminVerify: false };
    let data = { id: record._id, type: con };
    putPayment(data);
  };
  const handelVerify = (record) => {
    let con = { adminVerify: true };
    let data = { id: record._id, type: con };
    putPayment(data);
  };
  useEffect(() => {
    if (response && response.data) {
      toast.success(response.data.message);
    }
  }, [response]);

  return (
    <>
      <MainLayout>
        <div className="table">
          <Table
            dataSource={data && data.list}
            columns={columns}
            scroll={{ y: 400, x: 1000 }}
            bordered
            loading={isLoading}
            rowKey={(record) => record.id}
          />
        </div>
      </MainLayout>
    </>
  );
};

export default ManageInsurance;
