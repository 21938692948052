import React from "react";

import { Button, Image, Table } from "antd";
import { EditOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import MainLayout from "../../../component/MainLayout";

import { useGetUserProviderQuery } from "../../../redux/Api/adminApi";
import { useDispatch } from "react-redux";
import { showEditModalOpen } from "../../../redux/slice/healthSlice";

const ManageProvider = () => {
  const navigate = useNavigate();
  const { data } = useGetUserProviderQuery();
  const dispatch = useDispatch();

  const columns = [
    {
      key: "1",
      title: "Id",
      dataIndex: "_id",
    },
    {
      key: "2",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "3",
      title: "Email",
      dataIndex: "email",
    },
    {
      key: "4",
      title: "Image",
      dataIndex: "imageUrl",
      render: (record) =>
        record ? (
          <Image width={50} height={50} src={record} fallback="Uers-image" />
        ) : (
          "No Image"
        ),
    },
    {
      key: "5",
      title: "Provider Level",
      dataIndex: "level",
      render: (lvl) => (lvl === 1 ? <b>Level 1</b> : <p>Level 2</p>),
    },
    {
      key: "6",
      title: "Action",
      render: (record) => {
        return (
          <EditOutlined
            onClick={() => handelEdit(record)}
            style={{ color: "green", marginLeft: "20px" }}
          />
        );
      },
    },
  ];

  const handelEdit = (record) => {
    dispatch(showEditModalOpen(record));
    navigate("/user/provider-edit-form");
  };

  return (
    <>
      <MainLayout>
        <div className="table">
          <Button
            onClick={() => navigate("/user/provider-add-form")}
            className="gred-button"
          >
            Add Provider
          </Button>
          <Table
            dataSource={data && data.data}
            columns={columns}
            scroll={{ y: 400, x: 1000 }}
            bordered
          />
        </div>
      </MainLayout>
    </>
  );
};

export default ManageProvider;
