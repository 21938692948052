import React from "react";

import {Button,Space } from "antd";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const MainHeader = () => {
  const user = localStorage.getItem("user");
  const navigate = useNavigate();

  const handelLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/");
    toast.success("Logout Successfully");
  };

  return (
    <>
      <Space className="main-header">
        <p className="header-para"> welcome :- {user}</p>
        <Button onClick={handelLogout} className="gre-button">
          Log out
        </Button>
      </Space>
    </>
  );
};

export default MainHeader;
