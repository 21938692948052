import { Input, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import {showAddModalClose} from "../../../redux/slice/healthSlice"
import {showEditModalClose} from "../../../redux/slice/healthSlice"
import {usePostSpecialMutation,usePutSpecialMutation} from "../../../redux/Api/adminApi"
import { toast } from 'react-toastify'


export const SpecialAddModal = () => {
    const [specialAdd,setSpecialAdd]=useState()

    const {isModalAdd} = useSelector((state) => state.health)
    const dispatch = useDispatch()

    const [postSpecial,response] = usePostSpecialMutation()

    const handleOk =() => {
      if(specialAdd === undefined){
        return toast.error("please fill the data")
      }
        postSpecial(specialAdd)
        dispatch(showAddModalClose())
      };

      const handelChange = (e) => { 
        let {name,value} = e.target
        setSpecialAdd({...specialAdd,[name]:value})
        }
        useEffect(() => {
          if(response && response.data){
            toast.success( response.data.message)
          }else if(response.data && response.data.status === 400){
            toast.error( response.data.message)
            console.log(response.data.status)
          }

         },[response])

  return (
    <div>
       
      <Modal
          title="Add"
          open={isModalAdd}
          onOk={handleOk}
          onCancel={() =>dispatch(showAddModalClose())}
          okButtonProps={{ style: { background: 'var(--gradient)' } }} 
          okText="Add"
        >
          <div className="modal-input" >
          <Input
            placeholder="Enter Name"
            onChange={(e) => handelChange(e)}
            name="name"
             />
             </div>
             <div className="modal-input" >
          <Input
            placeholder="Enter Type"
            onChange={(e) => handelChange(e)}
            name="type"
             />
             </div>
           <div className="modal-input" >
          <Input
            placeholder="Enter Status"
            onChange={(e) => handelChange(e)}
            name="status"
          />
          </div>
          </Modal>
    </div>
  )
}


export const SpecialEditModal = () => {
    const [specialEdit,setSpecialEdit]=useState()
    const {isModalEdit,editRecord} = useSelector((state) => state.health)
    const dispatch = useDispatch()
    const [putSpecial,response] = usePutSpecialMutation()
    
    const handleOk =() => {
        let data = {
            id:editRecord._id,
            type:specialEdit
        }
        if(specialEdit === undefined){
          return toast.error("please fill the data")
        }
        putSpecial(data)
        dispatch(showEditModalClose()) 
      };
      const handelChange = (e) => { 
        let {name,value} = e.target
        setSpecialEdit({...specialEdit,[name]:value})
        }

        useEffect(() => {
          if(response && response.data){
            toast.success( response.data.message)
          }
         },[response])

    return (
      <div>
      <Modal
          title="Edit"
          open={isModalEdit}
          onOk={handleOk}
          onCancel={() =>dispatch(showEditModalClose())}
          okButtonProps={{ style: { background: 'var(--gradient)' } }} 
          okText="Edit"
        >
          <div className="modal-input" >
          <Input
            placeholder="Enter Name"
            onChange={(e) => handelChange(e)}
            name="name"
             />
          </div>
          
          <div className="modal-input" >
          <Input
            placeholder="Enter Type"
            onChange={(e) => handelChange(e)}
            name="type"
             />
             </div>
           <div className="modal-input" >
          <Input
            placeholder="Enter Status"
            onChange={(e) => handelChange(e)}
            name="status"
          />
          </div>
          </Modal>
      </div>
    )
  }


