import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import MainLayout from "../../../component/MainLayout";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { usePutStaticMutation } from "../../../redux/Api/adminApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const UpdateTerm = () => {
  const { editRecord } = useSelector((state) => state.health);
  const editor = useRef(null);
  const htmlData = editRecord&&editRecord.term_conditions.replace( /(<([^>]+)>)/ig, '').replace(/&nbsp;/g, " ")
  const [content, setContent] = useState(htmlData);
  const [putStatic, response] = usePutStaticMutation();
  const navigate = useNavigate()


  const handelSave = () => {
    const data = { id: editRecord._id, type: { term_conditions: content } };
    putStatic(data);
  };

  useEffect(() => {
    if (response && response.data) {
      toast.success(response.data.message);
      navigate("/static/term-and-condation");
    }
  }, [response]);

  return (
    <MainLayout>
      <div className="table">
        <div style={{ marginTop: "20px" }}>
          <Button
            onClick={() => navigate("/static/term-and-condation")}
            className="gred-button"
          >
            Back
          </Button>
        </div>
        <JoditEditor
          ref={editor}
          value={content}
          onBlur={(newContent) => setContent(newContent)}
          onChange={(newContent) => {}}
        />
        <div style={{ marginTop: "20px" }}>
          <Button onClick={handelSave} className="gred-button">
            Save
          </Button>
        </div>
      </div>
    </MainLayout>
  );
};

export default UpdateTerm;
