import React from "react";
import "../../styles/forget.css";

import { useNavigate } from "react-router-dom";

import { Button, Form, Input } from "antd";
import { ArrowLeftOutlined,UserOutlined } from "@ant-design/icons";

import { emailRule } from "../../Common/FormValidation";



const ForgotPassword = () => {
  const navigate = useNavigate();
  const onFinish = (value) => {
    console.log(value);
  };

  return (
    <>
      <div className="forget">
        <div className="forget-main">
          <h1>
            <ArrowLeftOutlined onClick={() => navigate("/")} />
          </h1>
          <h1 className="forget-head">Forgot Password</h1>
          <p className="forget-para">Please enter your email address to request a password reset.</p>
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item name="Email" rules={emailRule}>
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="email"
              />
            </Form.Item>
            <div className="login-button">
              <Button className="gre-button" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
