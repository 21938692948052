import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import MainLayout from "../../../component/MainLayout";
import { useSelector } from "react-redux";
import {
  useGetLevelTwoProvidersQuery,
  usePutUserProviderMutation,
} from "../../../redux/Api/adminApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "antd/es/form/Form";

const ManageProviderEdit = () => {
  const [file, setFile] = useState();
  const { editRecord } = useSelector((state) => state.health);
  const [putUserProvider, response] = usePutUserProviderMutation();
  const { data } = useGetLevelTwoProvidersQuery();
  const navigate = useNavigate();

  const [form] = useForm();
  console.log(editRecord);
  const onFinish = async (value) => {
    // console.log(value);
    // return;

    const fd = new FormData();
    fd.append("name", value.name);
    fd.append("level", value.level);
    if (value.assignedProviders)
      fd.append("assignedProviders", JSON.stringify(value.assignedProviders));
    if (file) fd.append("file", file);

    const data = {
      id: editRecord._id,
      type: fd,
    };
    putUserProvider(data);
  };

  useEffect(() => {
    if (response && response.data) {
      toast.success(response.data.message);
      navigate("/user/manage-provider");
    } else if (response && response.error) {
      toast.error(response.error.data.message);
    }
  }, [response]);

  const levelObj = [
    { label: "Level 1", value: 1 },
    { label: "Level 2", value: 2 },
  ];

  const levelTwoProviders = data?.data;

  return (
    <>
      <MainLayout>
        <div className="form-user">
          <h1 className="form-head"> Manage User Edit Form</h1>
          <Form
            form={form}
            initialValues={{
              name: editRecord?.name,
              level: editRecord?.level,
              assignedProviders: editRecord?.assignedProviders,
            }}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item label="Name" name="name">
              <Input />
            </Form.Item>
            <Form.Item label="Provider Level" name="level">
              <Select options={levelObj} />
            </Form.Item>

            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.level !== curValues.level
              }
              noStyle
            >
              {({ getFieldValue }) => {
                return getFieldValue("level") === 1 ? (
                  <Form.Item
                    label="Assign Level Two Providers"
                    name="assignedProviders"
                  >
                    <Select
                      placeholder="Select Providers"
                      showSearch
                      mode="multiple"
                      optionFilterProp="label"
                      options={levelTwoProviders}
                    />
                  </Form.Item>
                ) : null;
              }}
            </Form.Item>

            <Form.Item label="Upload Image">
              <Input type="file" onChange={(e) => setFile(e.target.files)} />
            </Form.Item>
            <Button type="primary" htmlType="submit" className="gred-button">
              Edit User
            </Button>
          </Form>
        </div>
      </MainLayout>
    </>
  );
};

export default ManageProviderEdit;
